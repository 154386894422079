import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function ResolveField({
  field,
  thema = null,
  content = null,
  asContent: Content,
  asMenu: Menu,
  asComponent: Component,
}) {
  function ContainerWrapper({ children: parentChildren }) {
    if (field.props.embed) return parentChildren;
    return (
      <Container
        fluid={field.props.fluid}
        className={field.props.className}
        {...(field.props.elementType !== ""
          ? { as: field.props.elementType }
          : {})}>
        <Row>{parentChildren}</Row>
      </Container>
    );
  }

  function ColWrapper({ children: parentChildren, ...props }) {
    if (field.props.embed) return parentChildren;
    return <Col {...props}>{parentChildren}</Col>;
  }

  if (field.cols.length === 0) return null;

  return (
    <ContainerWrapper>
      {field.cols.map(({ props, children }, index) => {
        const colProps = {};
        if (props.sm.span !== "0") colProps.sm = props.sm;
        if (props.md.span !== "0") colProps.md = props.md;
        if (props.lg.span !== "0") colProps.lg = props.lg;
        if (props.xl.span !== "0") colProps.xl = props.xl;
        if (props.className !== "") colProps.className = props.className;
        return (
          <ColWrapper {...colProps} key={index}>
            {children.map((child, childIndex) => {
              if (child.type === "menu") {
                return (
                  <Menu
                    key={childIndex}
                    content={content}
                    item={child.data}
                    itemProps={child.props}
                    parent={field}
                  />
                );
              }
              if (child.type === "content") {
                return (
                  <Content
                    key={childIndex}
                    content={content}
                    item={child.data}
                    itemProps={child.props}
                    parent={field}
                  />
                );
              }
              if (child.type === "component") {
                return (
                  <Component
                    thema={thema}
                    key={childIndex}
                    content={content}
                    item={child.data}
                    itemProps={child.props}
                    parent={field}
                  />
                );
              }
            })}
          </ColWrapper>
        );
      })}
    </ContainerWrapper>
  );

  // return (
  //   <Container
  //     fluid={field.props.fluid}
  //     className={field.props.className}
  //     {...(field.props.elementType !== ""
  //       ? { as: field.props.elementType }
  //       : {})}>
  //     <Row>
  //       {field.cols.map(({ props, children }, index) => {
  //         const colProps = {};
  //         if (props.sm.span !== "0") colProps.sm = props.sm;
  //         if (props.md.span !== "0") colProps.md = props.md;
  //         if (props.lg.span !== "0") colProps.lg = props.lg;
  //         if (props.xl.span !== "0") colProps.xl = props.xl;
  //         if (props.className !== "") colProps.className = props.className;
  //         return (
  //           <Col {...colProps} key={index}>
  //             {children.map((child) => {
  //               if (child.type === "menu")
  //                 return (
  //                   <Menu
  //                     content={content}
  //                     item={child.data}
  //                     itemProps={child.props}
  //                     parent={field}
  //                   />
  //                 );
  //               if (child.type === "content")
  //                 return (
  //                   <Content
  //                     content={content}
  //                     item={child.data}
  //                     itemProps={child.props}
  //                     parent={field}
  //                   />
  //                 );
  //               if (child.type === "component")
  //                 return (
  //                   <Component
  //                     content={content}
  //                     item={child.data}
  //                     itemProps={child.props}
  //                     parent={field}
  //                   />
  //                 );
  //             })}
  //           </Col>
  //         );
  //       })}
  //     </Row>
  //   </Container>
  // );
}
