import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { LINK } from "store/Queries/Link";
import ResolveField from "components/Layout/ResolveField";
import Layout from "components/Layout";
import NoMatch from "components/404";
import Loading from "components/Loading";
import Header from "components/Layout/Header";
export default function Content({ slug }) {
  const { data, loading, error } = useQuery(LINK.getLinkBySlug, {
    variables: { slug },
  });

  if (loading) return <Loading />;
  if (!data.getLinkBySlug) return <NoMatch />;
  if (!data.getLinkBySlug.masterPage) return "Ön Sayfa Ayarlanmamış";

  const ThemeComponent = React.lazy(() =>
    import(
      `../../Themes/${data.getLinkBySlug.masterPage.theme.folder}/index.js`
    )
  );

  return (
    <Layout>
      <Header
        setting={{
          metaTitle: data?.getLinkBySlug?.metaTitle,
          metaDescription: data?.getLinkBySlug?.metaDescription,
          metaKeywords: data?.getLinkBySlug?.metaKeywords,
        }}
      />
      <React.Suspense fallback={Loading}>
        <ThemeComponent
          thema={data?.getLinkBySlug?.masterPage?.theme}
          content={data}
          ResolveField={ResolveField}
          fields={data.getLinkBySlug.masterPage.fields.reduce((field, item) => {
            field[item.name] = item;
            return field;
          }, {})}
        />
      </React.Suspense>
    </Layout>
  );
}
